import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import NFTPreviewComponent from './NFTPreviewComponent';

export default function BannerContainer() {
  const userDataRedux = useSelector((state) => state.authReducer.userData);

  return (
    <>
      <div className="home center_container">
        <div className="container">
          <div className="home__banner">
            <div className="home_banner_con row">
              <div className="w-full lg:w-6/12 home_banner_con1">
                <div className="home__content">
                  {/* <img
                    src="/img/musicart-logo-ffffff-cropped.png"
                    alt=""
                    style={{
                      width: '15%',
                      margin: 'auto',
                      marginBottom: 20,
                    }}
                  /> */}
                  <h1
                    className="home__title"
                    style={{
                      fontSize: 28,
                      fontWeight: 500,
                      textAlign: 'center',
                    }}
                  >
                    {/* OWN THE ART OF MUSIC */}
                    WEB3 MUSIC MARKET
                  </h1>
                  <p className="home__text2 mt-10">
                    MusicArt is the easiest way for musicians to get music into
                    WEB3
                    {/* THE ONLY PLANET-FRIENDLY NFT MARKETPLACE for
                    encrypted music */}
                  </p>
                  <p className="home__text2 mt-10">
                    The only planet-friendly NFT marketplace for encrypted music
                  </p>
                  <p className="home__text2 mt-10">
                    LISTEN. PROMOTE. TRADE. <br />
                    <br />
                  </p>
                  <div className="home__btns">
                    <a
                      href={userDataRedux ? '/mintNFT' : '/auth/login'}
                      className="home__btn home_btn-bn"
                    >
                      Mint
                    </a>
                    <a href="/discover" className="home__btn">
                      Discover
                    </a>
                  </div>
                </div>
              </div>
              <NFTPreviewComponent />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

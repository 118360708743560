import { useEffect, useState } from 'react';
import NftCard from '../Card/NftCard';
import {
  collection,
  getDocs,
  limit,
  query,
  where,
  writeBatch,
  doc,
} from 'firebase/firestore/lite';
import { firebaseFireStore } from '../../config/firebaseFirestore';
// const NftCardskeleton = dynamic(() => import("../skeletons/nftcard"));
const skeleTonJson = [
  {
    tokenId: 'loader1',
    tokenName: 'loading',
    icons: null,
    artistId: 'loading',
    nftSaleType: 'loading',
    auctionEndTime: 'loading',
    startingPrice: 'loading',
    price: 'loading',
  },
  {
    tokenId: 'loader1',
    tokenName: 'loading',
    icons: null,
    artistId: 'loading',
    nftSaleType: 'loading',
    auctionEndTime: 'loading',
    startingPrice: 'loading',
    price: 'loading',
  },
  {
    tokenId: 'loader1',
    tokenName: 'loading',
    icons: null,
    artistId: 'loading',
    nftSaleType: 'loading',
    auctionEndTime: 'loading',
    startingPrice: 'loading',
    price: 'loading',
  },
  {
    tokenId: 'loader1',
    tokenName: 'loading',
    icons: null,
    artistId: 'loading',
    nftSaleType: 'loading',
    auctionEndTime: 'loading',
    startingPrice: 'loading',
    price: 'loading',
  },
];
export default function SpecialDrops() {
  const [drops, setDrops] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    getSpecialDrops();
  }, []);

  const getSpecialDrops = async () => {
    try {
      let queryRef = await getDocs(
        query(
          collection(firebaseFireStore, 'musicartNfts'),
          where('adminState', '==', 'ACCEPT'),
          where('activeInMarketPlace', '==', true),
          where('specialDrop', '==', true),
          limit(6),
        ),
      );

      if (queryRef.empty) {
        return null;
      }
      let dataArr = [];
      queryRef.forEach((doc) => {
        let cObj = doc.data();
        if (cObj.isMultiple && Number(cObj.nftQuantity) > 1) {
          let startingPrice = 0;
          setLoading(false);
          Object.values(cObj.multipleNftDetails)
            .filter((x) => x.activeInMarketPlace)
            .map((ele, index) => {
              if (ele.adminState === 'ACCEPT' && ele.activeInMarketPlace) {
                if (
                  ele.nftSaleType &&
                  ele.nftSaleType !== 'Auctioning' &&
                  ele.price &&
                  (!startingPrice ||
                    (startingPrice && Number(ele.price) < startingPrice))
                ) {
                  startingPrice = Number(ele.price);
                } else if (
                  ele.nftSaleType &&
                  ele.nftSaleType === 'Auctioning' &&
                  ele.auctionEndTime &&
                  ele.minBiddingPrice &&
                  (!startingPrice ||
                    (startingPrice &&
                      Number(ele.minBiddingPrice) < startingPrice))
                ) {
                  startingPrice = Number(ele.minBiddingPrice);
                }
              }
            });
          dataArr.push({ ...cObj, startingPrice: startingPrice });
        } else {
          dataArr.push(cObj);
        }
      });
      setDrops(dataArr);
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  // const test = async () => {
  //   console.log('start');

  //   let dataRef = await getDocs(
  //     query(collection(firebaseFireStore, 'musicartNfts')),
  //   );

  //   let batch = writeBatch(firebaseFireStore);
  //   dataRef.map((docEle) => {
  //     let cObj = docEle.data();
  //     console.log('nft ', docEle.id);

  //     let NftRef = doc(firebaseFireStore, 'musicartNfts', docEle.id);
  //     // batch.update(NftRef, { price: Number(startingPrice) });
  //   });

  //   // await batch.commit();

  //   // let dataRef = await getDocs(
  //   //   query(collection(firebaseFireStore, 'musicartNfts')),
  //   // );

  //   // let batch = writeBatch(firebaseFireStore);
  //   // dataRef.forEach((docEle) => {
  //   //   let cObj = docEle.data();
  //   //   if (cObj.isMultiple) {
  //   //     console.log('fix multi ', docEle.id);

  //   //     let startingPrice = 0;
  //   //     Object.values(cObj.multipleNftDetails)
  //   //       .filter((x) => x.activeInMarketPlace)
  //   //       .map((ele, index) => {
  //   //         if (ele.adminState === 'ACCEPT' && ele.activeInMarketPlace) {
  //   //           if (
  //   //             ele.nftSaleType &&
  //   //             ele.nftSaleType !== 'Auctioning' &&
  //   //             ele.price &&
  //   //             (!startingPrice ||
  //   //               (startingPrice && Number(ele.price) < startingPrice))
  //   //           ) {
  //   //             startingPrice = Number(ele.price);
  //   //           } else if (
  //   //             ele.nftSaleType &&
  //   //             ele.nftSaleType === 'Auctioning' &&
  //   //             ele.auctionEndTime &&
  //   //             ele.minBiddingPrice &&
  //   //             (!startingPrice ||
  //   //               (startingPrice &&
  //   //                 Number(ele.minBiddingPrice) < startingPrice))
  //   //           ) {
  //   //             startingPrice = Number(ele.minBiddingPrice);
  //   //           }
  //   //         }
  //   //       });

  //   //     console.log('startingPrice', startingPrice);
  //   //     let NftRef = doc(firebaseFireStore, 'musicartNfts', docEle.id);
  //   //     batch.update(NftRef, { price: Number(startingPrice) });
  //   //   }
  //   // });

  //   // await batch.commit();

  //   //single nfts

  //   // let dataRef = await getDocs(
  //   //   query(collection(firebaseFireStore, 'musicartNfts')),
  //   // );

  //   // let batch = writeBatch(firebaseFireStore);
  //   // dataRef.forEach((docEle) => {
  //   //   let cObj = docEle.data();
  //   //   if (!cObj.isMultiple) {
  //   //     if (
  //   //       cObj.nftSaleType &&
  //   //       cObj.nftSaleType === 'Auctioning' &&
  //   //       cObj.minBiddingPrice
  //   //     ) {
  //   //       console.log('fix required', docEle.id);

  //   //       let NftRef = doc(firebaseFireStore, 'musicartNfts', docEle.id);
  //   //       batch.update(NftRef, { price: Number(cObj.minBiddingPrice) });
  //   //     }
  //   //   }
  //   // });

  //   // await batch.commit();
  // };

  return (
    <>
      {drops && drops.length > 0 && (
        <section className="center_container special-drops">
          <div className="container home_container mx-auto">
            <div className="main__title px-4">
              <h2>
                Special Drops
                <img
                  src="img/sparkles.svg"
                  style={{ width: 35, height: 30, margin: 6 }}
                />
              </h2>
            </div>
            {/* <button onClick={test}>test</button> */}
            <div className="nft-card-con">
              {loading &&
                skeleTonJson.map((ele, index) => (
                  <div
                    style={{ display: 'block' }}
                    className="turning-screens 2xl:w-2/12 xl:w-5/12 lg:w-3/12 md:w-4/12  "
                    key={'special_' + index}
                  >
                    {' '}
                    <NftCard
                      // nftDataArr={nftDataArr}
                      ele={ele}
                      index={index}
                      // setNFTDataArr={setNFTDataArr}
                    />
                  </div>
                ))}

              {drops &&
                drops.map((ele, index) => {
                  if (ele.activeInMarketPlace) {
                    return (
                      <div
                        style={{ display: 'block' }}
                        className="turning-screens 2xl:w-2/12 xl:w-5/12 lg:w-3/12 md:w-4/12  "
                        key={'special_' + index}
                      >
                        <NftCard
                          nftDataArr={[...drops]}
                          setnftDataArr={setDrops}
                          ele={ele}
                          index={index}
                          category="specialDrop"
                        />
                      </div>
                    );
                  }
                })}
            </div>
          </div>
        </section>
      )}
    </>
  );
}

import React, { useEffect, useState } from 'react';
import { AspectRatio } from 'react-aspect-ratio';
import ReactPlayer from 'react-player/lazy';
import PlayCircleIcon from '@mui/icons-material/PlayCircle';
import PauseCircleIcon from '@mui/icons-material/PauseCircle';
import Fab from '@material-ui/core/Fab';
import Lottie from 'react-lottie';
import musicAnimation from '../Card/music-lottie';
import router from 'next/router';

export default function AudioComponent(props) {
  const [audioPlay, setaudioPlay] = useState(false);

  return (
    <>
      <div
        style={{
          height: '100%',
          width: '100%',
          aspectRatio: '1/1',
        }}
        onClick={() =>
          router.push(
            `/nft/${props.ele && props.ele.tokenId ? props.ele.tokenId : ''}`,
          )
        }
        className="cursor-pointer"
      >
        <AspectRatio
          ratio="1/1"
          style={{
            width: '100%',
          }}
        >
          <div
            style={{
              height: '100%',
              width: '100%',
              display: 'flex',
            }}
          >
            <AspectRatio
              ratio="1/1"
              style={{
                width: '100%',

                backgroundSize: 'cover',
                position: 'relative',
              }}
            >
              {' '}
              <div
                className="skeleton-moon "
                style={{
                  width: '100%',
                  height: '100%',
                  borderRadius: '20px 20px 0 0',
                }}
              />
            </AspectRatio>
            <AspectRatio
              ratio="1/1"
              style={{
                width: '100%',
                position: 'absolute',
                top: 0,
              }}
            >
              <div
                // className="top-0 left-0 w-full h-full object-cover"
                className="absolute w-full h-full bg-cover bg-center"
                width="100%"
                height="100%"
                style={{
                  backgroundImage: `url(${
                    props.ele &&
                    props.ele.audioCoverFile250 &&
                    Array.isArray(props.ele.audioCoverFile250) &&
                    props.ele.audioCoverFile250[0]
                      ? props.ele.audioCoverFile250[0]
                      : props.ele.audioCoverFile250
                      ? props.ele.audioCoverFile250
                      : '/img/cover/cover_260x260.jpg'
                  })`,
                }}
                // onClick={(e) => {
                //   e.stopPropagation();
                //   if (props.sliderRef) {
                //     if (!audioPlay) {
                //       props.sliderRef.current.slickPause();
                //     } else {
                //       props.sliderRef.current.slickPlay();
                //     }
                //   }
                //   setaudioPlay(!audioPlay);
                // }}
              >
                {audioPlay && (
                  <Lottie
                    options={{
                      loop: true,
                      autoplay: true,
                      animationData: musicAnimation,
                    }}
                    speed={1}
                    isClickToPauseDisabled={true}
                    style={{
                      margin: 'auto',
                      width: '100%',
                      position: 'absolute',
                      zIndex: 1001,
                    }}
                  />
                )}
                <div className="flex items-end absolute">
                  <div
                    className="ml-auto w-full flex justify-center items-center blue_color btn_overwrite_background"
                    style={{
                      width: 60,
                      height: 60,
                      marginRight: 0,
                      zIndex: 100000,
                    }}
                  >
                    <Fab
                      //   size="medium"
                      color="secondary"
                      style={{
                        background: 'transparent',
                        boxShadow: 'none',
                      }}
                      className="btn-color"
                      onClick={(e) => {
                        console.log('gggggggggggggggg');
                        e.stopPropagation();
                        if (props.sliderRef) {
                          if (!audioPlay) {
                            props.sliderRef.current.slickPause();
                          } else {
                            props.sliderRef.current.slickPlay();
                          }
                        }
                        setaudioPlay(!audioPlay);
                      }}
                    >
                      {!audioPlay ? (
                        <PlayCircleIcon fontSize="large" />
                      ) : (
                        <PauseCircleIcon fontSize="large" />
                      )}
                    </Fab>
                  </div>
                </div>
                <ReactPlayer
                  playsinline={true}
                  height="100%"
                  width="100%"
                  url={props.ele.sampleAudioFile}
                  controls={false}
                  playing={audioPlay}
                  onEnded={() => {
                    if (audioPlay) {
                      setaudioPlay(false);

                      if (props.sliderRef) {
                        props.sliderRef.current.slickPlay();
                      }
                    }
                  }}
                />
              </div>
            </AspectRatio>
          </div>
        </AspectRatio>
      </div>
    </>
  );
}

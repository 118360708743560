export default {
    title:'MusicArt',
    description:'A planet-friendly NFT marketplace where music artwork reigns supreme.',
    openGraph:{
        type: 'website',
        locale: 'en_IE',
        url: 'https://musicart.io/',
        site_name: 'MusicArt',
        images: [
          {
            url: "https://i.imgur.com/Q0kiEsg.jpg",
            width: 800,
            height: 600,
            alt: "MusicArt Image",
          },
        ],
      },
      canonical : 'https://musicart.io'
}
import React from 'react';
import Navbar from '../components/Navbars/AppNavbar.js';
import Footer from '../components/Footers/Footer.js';
import FooterSM from '../components/Footers/FooterSmall.js';
import MarketPlaceComponent from '../components/MarketPlace';
import Head from 'next/head';

import { DefaultSeo } from 'next-seo';
import SEO from '../next-sco.config';

export default function Landing() {
  return (
    <>
      <DefaultSeo {...SEO} />
      <Head>
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1, shrink-to-fit=no"
        />
        {/* <Link  rel="canonical" href="https://musicart.io/" /> */}
      </Head>

      <Navbar transparent />
      <MarketPlaceComponent />
      <Footer />
      <FooterSM />
    </>
  );
}

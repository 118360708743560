import React from 'react';
import dynamic from 'next/dynamic';
// import { useSelector } from "react-redux";
import BannerContainer from './BannerContainer';
import SpecialDrops from './SpecialDrops';
import Music from './Music';
const NftsContainer = dynamic(() => import('./NftsContainer'));
const IntroContainer = dynamic(() => import('./IntroContainer'));
const CollectorsContainer = dynamic(() => import('./CollectorContainer'));
const FeaturesContainer = dynamic(() => import('./FeaturesContainer'));
const ProcessContainer = dynamic(() => import('./BuyProcess'));
const MusicArtistContainer = dynamic(() =>
  import('../MusicArtist/MusicArtistContainer'),
);
const NewArtWorks = dynamic(() => import('./NewArtWorks'));

export default function MarketPlaceComponent(props) {
  // console.log(router)
  // const userDataRedux = useSelector((state) => state.authReducer.userData);
  const [showContent, setShowContent] = React.useState(false);
  const handleScroll = () => {
    const position = window.pageYOffset;
    // console.log(position)
    if (!showContent && position) {
      setShowContent(true);
    }
    // setScrollPosition(position);
  };

  React.useEffect(() => {
    window.addEventListener('scroll', handleScroll, { passive: true });
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);
  return (
    <main className="main off-screens-for-main-only">
      <BannerContainer />
      <Music />
      <MusicArtistContainer />
      <SpecialDrops />

      {showContent && (
        <>
          <NftsContainer />
          <ProcessContainer />
          <IntroContainer />
          {/* <CollectorsContainer /> */}
          <NewArtWorks />
          <FeaturesContainer />
        </>
      )}
    </main>
  );
}

import React, { useEffect, useState, useRef } from 'react';
import Link from 'next/link';
import Slider from 'react-slick';
import Skeleton from '@material-ui/lab/Skeleton';
import { useSelector } from 'react-redux';
import Image from 'next/image';
import {
  collection,
  doc,
  getDoc,
  getDocs,
  limit,
  orderBy,
  query,
  where,
} from 'firebase/firestore/lite';
import { firebaseFireStore } from '../../config/firebaseFirestore';
import { AspectRatio } from 'react-aspect-ratio';
// import {isWebpSupported} from 'react-image-webp/dist/utils';
import ReactPlayer from 'react-player/lazy';
import AudioComponent from './AudioComponent';

const settings = {
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  autoplay: true,
  autoplaySpeed: 4000,
  dotsClass: 'slick-dots slick-thumb',
  appendDots: (dots) => (
    <div
      style={{
        marginBottom: 45,
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'flex-start',
      }}
    >
      <div
        onClick={(e) => {
          e.preventDefault();
        }}
        style={{
          cursor: 'default',
        }}
      >
        <ul className="discover-dots-ul"> {dots} </ul>
      </div>
    </div>
  ),
  customPaging: (i) => <div className="dots-discover"></div>,
};
const settings2 = {
  autoplay: true,

  dots: false,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  autoplaySpeed: 4000,
  dotsClass: 'slick-dots slick-thumb',
};
export default function NFTPreviewComponent() {
  const sliderRef = useRef();
  const [featuredNftObj, setfeaturedNftObj] = useState([]);

  useEffect(() => {
    getFeaturedNft();
  }, []);

  const getArtistDetails = async (artistId) => {
    let dbRef = await getDoc(
      doc(firebaseFireStore, 'musicartArtists', artistId),
    );
    if (dbRef.exists) {
      return { ...dbRef.data(), artistId: artistId };
    }

    return null;
  };

  const getFeaturedNft = async () => {
    let queryRef = query(
      collection(firebaseFireStore, 'musicartNfts'),
      where('adminState', '==', 'ACCEPT'),
      where('activeInMarketPlace', '==', true),
      where('featuredNft.status', '==', true),
    );

    let dataRef = await getDocs(queryRef);

    if (!dataRef.empty) {
      let dropsArr = [];
      await Promise.all(
        dataRef.docs.map(async (docEle) => {
          let cObj = docEle.data();
          let artistData = await getArtistDetails(cObj.artistId);
          if (artistData) {
            cObj = { ...cObj, ...artistData };
          }
          dropsArr.push(cObj);
        }),
      );
      setfeaturedNftObj([
        ...dropsArr.sort((a, b) => a.featuredNft?.index - b.featuredNft?.index),
      ]);
    }
  };

  return (
    <div className="w-full lg:w-6/12 home_banner_con2">
      <div className="new-slider-feature">
        <div style={{ display: 'block' }} className="inside-new">
          {featuredNftObj && featuredNftObj.length > 0 && (
            <Slider
              {...settings2}
              style={{ height: '100%' }}
              ref={(slider) => (sliderRef.current = slider)}
            >
              {featuredNftObj.map((featuredNftObj1, index) => {
                return (
                  <div
                    className="featured_nft"
                    style={{ minHeight: '150px' }}
                    key={'nft-card783-' + index}
                  >
                    <div className="featured_img">
                      {(() => {
                        if (featuredNftObj1.nftType === 'Audio') {
                          return (
                            <>
                              <AudioComponent
                                ele={featuredNftObj1}
                                sliderRef={sliderRef}
                                index={index}
                              />

                              {!featuredNftObj1 && (
                                <Skeleton
                                  animation="wave"
                                  variant="rect"
                                  height={'1000'}
                                  style={{ borderRadius: 20 }}
                                />
                              )}
                            </>
                          );
                        } else {
                          return (
                            <Link
                              prefetch={false}
                              href={`/nft/${
                                featuredNftObj1 && featuredNftObj1.tokenId
                                  ? featuredNftObj1.tokenId
                                  : ''
                              }`}
                            >
                              <a
                                style={{
                                  display: 'block',
                                  height: '100%',
                                }}
                              >
                                {(() => {
                                  if (1) {
                                    let ele = featuredNftObj1.icons[0];
                                    return (
                                      <div
                                        style={{
                                          height: '100%',
                                          width: '100%',
                                          aspectRatio: '1/1',
                                        }}
                                        key={ele}
                                      >
                                        <AspectRatio
                                          ratio="1/1"
                                          style={{
                                            width: '100%',
                                          }}
                                        >
                                          <div
                                            style={{
                                              height: '100%',
                                              width: '100%',
                                              display: 'flex',
                                            }}
                                          >
                                            {/* <AspectRatio
                                              ratio="1/1"
                                              style={{
                                                width: '100%',

                                                backgroundSize: 'cover',
                                                position: 'relative',
                                              }}
                                            >
                                              {' '}
                                              <div
                                                className="skeleton-moon "
                                                style={{
                                                  width: '100%',
                                                  height: '100%',
                                                  borderRadius: '20px 20px 0 0',
                                                }}
                                              />
                                            </AspectRatio> */}
                                            <AspectRatio
                                              ratio="1/1"
                                              style={{
                                                width: '100%',
                                                position: 'absolute',
                                                top: 0,
                                              }}
                                            >
                                              {ele.includes('_video') ? (
                                                <div>
                                                  <ReactPlayer
                                                    playsinline={true}
                                                    height="100%"
                                                    width="100%"
                                                    url={ele}
                                                    controls={true}
                                                    playing={true}
                                                    muted={true}
                                                    loop={true}
                                                  />
                                                </div>
                                              ) : (
                                                <img
                                                  onLoad={(event) => {
                                                    event.target.style.opacity =
                                                      '1';
                                                  }}
                                                  style={{
                                                    transition:
                                                      'all 0.1s ease-in-out',
                                                    opacity: 0,
                                                  }}
                                                  src={
                                                    ele
                                                      ? ele.includes(
                                                          '_250x250?alt=media',
                                                        )
                                                        ? ele.replace(
                                                            '_250x250?alt=media',
                                                            '_1500x1500?alt=media',
                                                          )
                                                        : ele
                                                      : '/img/cover/cover_260x260.jpg'
                                                  }
                                                />
                                              )}
                                            </AspectRatio>
                                          </div>
                                        </AspectRatio>
                                      </div>
                                    );
                                  }
                                })()}

                                {!featuredNftObj1 && (
                                  <Skeleton
                                    animation="wave"
                                    variant="rect"
                                    height={'1000'}
                                    style={{ borderRadius: 20 }}
                                  />
                                )}
                              </a>
                            </Link>
                          );
                        }
                      })()}
                    </div>

                    <div className="featured_tag_box">
                      <Link
                        prefetch={false}
                        href={`/artist/${featuredNftObj1.artistId}`}
                      >
                        <a>
                          <div className={'card__author featured_card__author'}>
                            <div className="author__avatar1 ">
                              <div width={40} height={40}>
                                <img
                                  onLoad={(event) => {
                                    event.target.style.opacity = '1';
                                  }}
                                  style={{
                                    position: 'absolute',
                                    transition: 'all 0.1s ease-in-out',
                                    opacity: 0,
                                  }}
                                  width="100%"
                                  height="100%"
                                  src={
                                    featuredNftObj1 && featuredNftObj1.photoUrl
                                      ? featuredNftObj1.photoUrl +
                                        '_250x250?alt=media'
                                      : '/img/avatars/avatar.jpg'
                                  }
                                  alt="artist-image"
                                />
                              </div>
                            </div>
                          </div>
                        </a>
                      </Link>

                      <div
                        className="featured_nft_text"
                        // style={{ border: '2px solid red' }}
                      >
                        <h2 className="text_in_one_line">
                          <Link
                            prefetch={false}
                            href={`/nft/${
                              featuredNftObj1 && featuredNftObj1.tokenId
                                ? featuredNftObj1.tokenId
                                : ''
                            }`}
                          >
                            <a>
                              <span className="text-black">
                                {featuredNftObj1 && featuredNftObj1.tokenName
                                  ? featuredNftObj1.tokenName
                                  : '-'}
                              </span>
                            </a>
                          </Link>
                        </h2>

                        <Link
                          prefetch={false}
                          href={`/artist/${
                            featuredNftObj1 && featuredNftObj1.artistId
                              ? featuredNftObj1.artistId
                              : ''
                          }`}
                        >
                          <a>
                            <span className="text_in_one_line">
                              {featuredNftObj1 && featuredNftObj1.name ? (
                                featuredNftObj1.name
                              ) : (
                                <div
                                  className="skeleton-moon"
                                  style={{
                                    transform: 'scale(1,0.7)',
                                    color: '#e0e0e0',
                                    width: '40%',
                                    borderRadius: 5,
                                  }}
                                >
                                  VVVVVVV
                                </div>
                              )}
                            </span>
                          </a>
                        </Link>
                      </div>
                    </div>
                  </div>
                );
              })}
            </Slider>
          )}
        </div>
      </div>
    </div>
  );
}
